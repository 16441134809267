import { useWindowSize } from 'usehooks-ts';

import UiUtils from '../utils/UiUtils';

import Logo from '../images/seedkit_logo_black.svg';

export default function Footer({ className }) {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    let outerClass = isDesktop ? "flex flex-col items-stretch rounded-3xl overflow-hidden" : "flex flex-col items-stretch rounded-3xl overflow-hidden";
    if (className) {
        outerClass = `${outerClass} ${className}`;
    }

    const footerLinkClass = "font-vg-regular text-base py-[6px] no-underline";

    return (
        <div className={outerClass}>
            {isDesktop && (
                <>
                    <div className="p-8 bg-black">
                        <p className="font-vg-medium text-white text-xl border-box max-w-content-narrow mx-auto">
                            Seedkit acknowledges the Traditional Owners of the unceded lands on which we work, learn and live, 
                            and recognises the unique place held by Aboriginal and Torres Strait Islander peoples as the original owners 
                            and custodians of the lands, skies and waterways of the Australian continent.
                        </p>
                    </div>
                    <div className="flex flex-col items-stretch gap-6 pt-20 px-8 pb-6 bg-grey04">
                        <div className="w-full flex justify-between text-black border-box max-w-content-narrow mx-auto">
                            <div className="flex flex-col gap-4 items-start max-w-[501px]">
                                <img src={Logo} alt="Seedkit logo" className="h-[39px]"/>
                                <p className="font-vg-book text-base text-black">
                                    Seedkit is powered by the Centre for Social Impact Swinburne and 
                                    Melbourne Social Equity Institute and funded by Victoria State Government
                                </p>
                            </div>
                            <div className="col-start-5 flex flex-col gap-3 w-[199px]">
                                <p className="font-vg-regular text-xl">Seedkit</p>
                                <a href="/about" className={footerLinkClass}>About</a>
                                <a href="/login" className={footerLinkClass}>Login</a>
                                <a href="/resources" className={footerLinkClass}>Resources</a>
                                <a href="/contact" className={footerLinkClass}>Contact</a>
                            </div>
                        </div>
                        <div className="w-full h-0 border border-grey02 border-box max-w-content-narrow mx-auto"></div>
                        <div className="w-full flex items-center justify-between border-box max-w-content-narrow mx-auto">
                            <div className="flex items-center gap-6 font-vg-regular text-sm text-grey">
                                <a href="/terms" className="no-underline">Terms of Use</a>
                                <a href="/privacy" className="no-underline">Privacy Policy</a>
                            </div>
                            <p className="font-vg-book text-black text-xs2">
                                Copyright ©2023 All rights reserved.
                            </p>
                        </div>
                    </div>
                </>
            )}
            {isMobile && (
                <>
                    <div className="py-8 px-6 bg-black">
                        <p className="font-vg-medium text-white text-xl">
                            Seedkit acknowledges the Traditional Owners of the unceded lands on which we work, learn and live, 
                            and recognises the unique place held by Aboriginal and Torres Strait Islander peoples as the original owners 
                            and custodians of the lands, skies and waterways of the Australian continent.  
                        </p>
                    </div>
                    <div className="flex flex-col items-stretch gap-6 py-8 px-6 bg-grey04">
                        <div className="flex flex-col gap-6">
                            <div className="flex flex-col gap-4 items-start">
                                <img src={Logo} alt="Seedkit logo" className="h-[39px]"/>
                                <p className="font-vg-book text-base text-black">
                                    Seedkit is powered by the Centre for Social Impact Swinburne and 
                                    Melbourne Social Equity Institute and funded by Victoria State Government
                                </p>
                            </div>
                            <div className="flex flex-col gap-3">
                                <p className="font-vg-regular text-xl">Seedkit</p>
                                <a href="/about" className={footerLinkClass}>About</a>
                                <a href="/login" className={footerLinkClass}>Login</a>
                                <a href="/resources" className={footerLinkClass}>Resources</a>
                                <a href="/contact" className={footerLinkClass}>Contact</a>
                            </div>
                        </div>
                        <div className="h-0 border border-grey02"></div>
                        <div className="flex flex-col gap-8">
                            <div className="flex flex-col gap-6 font-vg-regular text-sm text-grey">
                                <a href="/terms" className="no-underline">Terms of Use</a>
                                <a href="/privacy" className="no-underline">Privacy Policy</a>
                            </div>
                            <p className="font-vg-book text-black text-xs2">
                                Copyright <span className="text-lg">©</span>2023 All rights reserved.
                            </p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
