import { useId } from 'react';
import { Tab } from '@headlessui/react';

import UiUtils from '../utils/UiUtils';

export default function Tabs(props) {
    const { id, titles, listClass, tabClass, panelsClass, children } = props;

    const newId = useId();
    const tabId = id ? id : newId;

    return (
        <div className="w-full flex flex-col items-stretch">
        <Tab.Group>
            <Tab.List className={UiUtils.classNames("flex justify-start items-baseline gap-3", listClass)}>
                {titles.map((title, index) => (
                    <Tab 
                        key={`${tabId}-tab-${index}`}
                        className={UiUtils.classNames("font-vg-regular text-sm py-3 px-0 text-black border-b border-transparent ui-selected:text-blue ui-selected:border-blue outline-none", tabClass)}
                    >
                        {title}
                    </Tab>
                ))}
            </Tab.List>
            <Tab.Panels className={panelsClass}>
                {children}
            </Tab.Panels>
        </Tab.Group>
        </div>
    );
}