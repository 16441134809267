import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';

import AuthService from '../../services/AuthService';
import UiUtils from '../../utils/UiUtils';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';

export default function ForgotPasswordPage() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    let containerClass = "box-border w-full h-full flex flex-col items-stretch";

    containerClass = isDesktop ? `${containerClass} px-16 py-6 gap-16` : `${containerClass} px-5 py-6 gap-12`;

    const [email, setEmail] = useState('');
    const [submitCss, setSubmitCss] = useState('');
	const [emailError, setEmailError] = useState('');
    const [submitError, setSubmitError] = useState('');
	const [sendStatus, setSendStatus] = useState('');

    const navigate = useNavigate();
    
    function handleEmailChange(e) {
		setEmail(e.target.value);
		setEmailError('');
        setSubmitError('');
    }

    function handleEmailSubmit() {
        if (email !== "") {
			setSubmitCss('hidden');
			setSendStatus('Processing...');
            AuthService.startForgotPassword(email)
            .then(response => {
                navigate('/password/forgot/sent');
            })
            .catch(error => {
				setSubmitCss('');
				setSendStatus('');
				console.log('error', error);
				setSubmitError(error.response && error.response.data ? error.response.data.message || error.message : error.message);
            });
        }
    }

    return(
    <div className={containerClass}>
        <Header title="Forgot Password"/>
        <div className="grid grid-cols-12">
            <div className="col-start-4 col-span-6 flex flex-col items-stretch gap-10 rounded-3xl bg-grey03 px-8 py-12">
                <h2 className="font-vg-medium text-black text-[32px]">
                    Reset your password
                </h2>
                <div className="flex flex-col items-stretch gap-4">
                    <p className="font-vg-book text-black text-base">If you have forgotten your password, you will need to reset it and enter a new one. To start this process, enter the email address of your account.</p>
                    <TextInput
                        id="email"
                        label="Email"
                        variant={emailError ? "error" : "default"}
                        value={email}
                        help={emailError}
                        onChange={handleEmailChange}
                    />
                    <div className="flex items-center justify-between gap-4">
                        <Button variant="solid" size="large" label="SUBMIT" className={submitCss} onClick={handleEmailSubmit}/>
                        <p className="font-vg-medium text-base text-black h-9" aria-live="polite">{sendStatus}</p>
                        <p className="font-vg-bold text-base text-red my-5 min-h-8 leading-8" aria-live="polite">{submitError}</p>
                    </div>
                    	
                </div>
            </div>
        </div>
        <Footer/>
    </div>
    );
}