import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import AuthService from '../../services/AuthService';
import UiUtils from '../../utils/UiUtils';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';

export default function ChangePasswordPage() {
  	const { width } = useWindowSize();
  	const isMobile = UiUtils.isMobile(width);
  	const isDesktop = !isMobile;

  	let containerClass = "box-border w-full h-full flex flex-col items-stretch";

  	containerClass = isDesktop ? `${containerClass} px-16 py-6 gap-16` : `${containerClass} px-5 py-6 gap-12`;

  	const [submitCss, setSubmitCss] = useState('');
  	const [submitStatus, setSubmitStatus] = useState('');
  	const [submitError, setSubmitError] = useState('');

  	const navigate = useNavigate();

	const validationSchema = Yup.object().shape({
		password: Yup.string().required('Please enter a password'),
		confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], "Passwords do not match!")
  	});
  	const formOptions = { 
		resolver: yupResolver(validationSchema),
		defaultValues: {
			password: "",
			confirmPassword: "",
		} 
  	};
  	const { 
		control, 
		handleSubmit,
		watch
  	} = useForm(formOptions);

	function handleChangePassword(data) {
    	if (data.password !== '') {
			if (data.password === data.confirmPassword) {
				setSubmitCss('hidden');
				setSubmitStatus('Processing...');
				AuthService.changePassword(data.password)
				.then((_) => {
					navigate('/kit/home');
				})
				.catch(error => {
					setSubmitCss('');
					setSubmitStatus('');
					console.log('error', error);
					setSubmitError(error.response && error.response.data ? error.response.data.message || error.message : error.message);
				});
			} else {
				setSubmitError('Passwords do not match');
			}
    	} else {
      	setSubmitError('You need to enter a new password');
    	}
  	}

	useEffect(() => {
		const subscription = watch((data, { name, type }) => {
			setSubmitError('');
	  	});
	  	return () => subscription.unsubscribe();
 	}, [watch, setSubmitError]);

  	return(
		<div className={containerClass}>
			<Header title="Change Password"/>
			<div className="container mx-auto px-3 text-black pb-10">
				<div className="w-full max-w-screen-sm mx-auto px-3">
					<h2 className="font-vg-medium text-3xl text-center my-10">
						Change Password
					</h2>
					<form className="relative w-full flex flex-col items-stretch gap-2" onSubmit={handleSubmit(handleChangePassword)}>
						<Controller
							name="password"
							control={control}
							render={({ field, formState }) => (
								<TextInput
									type="password" 
									variant={formState.errors.password ? "error" : "default"}
									label="Enter New Password"
									help={formState.errors.password?.message} 
									showHelp="always" 
									{...field}
								/>
							)}
						/>
						<Controller
							name="confirmPassword"
							control={control}
							render={({ field, formState }) => (
								<TextInput
									type="password" 
									variant={formState.errors.confirmPassword ? "error" : "default"}
									label="Confirm Password"
									help={formState.errors.confirmPassword?.message} 
									showHelp="always" 
									{...field}
								/>
							)}
						/>

						<div className="flex items-center mt-4">
							<Button type="submit" variant="solid" size="large" className={submitCss} label="CHANGE PASSWORD"/>
							<p className="font-vg-medium text-base text-black h-9" aria-live="polite">{submitStatus}</p>
						</div>

						<p className="font-vg-bold text-base text-red my-5 min-h-8 leading-8" aria-live="polite">{submitError}</p>
					</form>
				</div>
			</div>
			<Footer/>
		</div>
  	);
}