import { useWindowSize } from 'usehooks-ts';

import UiUtils from '../utils/UiUtils';

import CSISwinLogo from '../images/csi_swin_logo.png';
import MSEILogo from '../images/msei_logo_bw.jpg';
import VicGovLogo from '../images/vic_gov_logo.png';
import LMCFLogo from '../images/lmcf_logo.png';

export default function LogoBlock() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    return (
        <>
            {isDesktop && (
                <div className="flex flex-col items-center gap-8 w-[842px] py-10 mx-auto">
                    <div className="flex flex-col items-center gap-4 w-[644px] text-black">
                        <h4 className="font-vg-medium text-3.5xl">Sponsors and partners</h4>
                        <p className="font-vg-book text-base">
                            Seedkit is an initiative of the Centre for Social Impact Swinburne and the Melbourne Social Equity Institute. 
                            Its initial development was funded by Victoria State Government and it is currently funded by Lord Mayor's 
                            Charitable Foundation and the University of Melbourne.
                        </p>
                    </div>
                    <div className="flex gap-12 items-center justify-center">
                        <img src={CSISwinLogo} className=" h-[60px]" alt="Centre for Social Impact Swinburne University logo"/>
                        <img src={MSEILogo} className=" h-[90px]" alt="Melbourne Social Equity Institute logo"/>
                        <img src={VicGovLogo} className=" h-[60px]" alt="Victoria State Government logo"/>
                        <img src={LMCFLogo} className=" h-[80px]" alt="Lord Mayors Charitable Foundation logo"/>
                    </div>
                </div>
            )}
            {isMobile && (
                <div className="flex flex-col items-center gap-8 py-8 px-6">
                    <div className="flex flex-col items-center gap-3 text-black">
                        <h4 className="font-vg-medium text-[28px]">Sponsors and partners</h4>
                        <p className="font-vg-book text-base">
                            Seedkit is an initiative of the Centre for Social Impact Swinburne and the Melbourne Social Equity Institute. 
                            Its initial development was funded by Victoria State Government and it is currently funded by Lord Mayor's 
                            Charitable Foundation and the University of Melbourne.
                        </p>
                    </div>
                    <div className="flex flex-col items-center gap-8">
                        <img src={CSISwinLogo} className=" h-[70px]" alt="Centre for Social Impact Swinburne University logo"/>
                        <img src={MSEILogo} className=" h-[90px]" alt="Melbourne Social Equity Institute logo"/>
                        <img src={VicGovLogo} className=" h-[60px]" alt="Victoria State Government logo"/>
                        <img src={LMCFLogo} className=" h-[80px]" alt="Lord Mayors Charitable Foundation logo"/>
                    </div>
                </div>
            )}
        </>
    );
}