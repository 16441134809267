import { useId } from 'react';
import { lookupIcon } from 'heroicons-lookup';

import IconTooltip from './IconTooltip';

export default function Table(props) {
    const { columns, data, actions, variant, hideHeaders, noDataMessage, scrolling, border, className, onActionClick, ...otherProps } = props;

    const headersHidden = (hideHeaders === true || hideHeaders === "true") ? true : false;
    const hasBorder = (border === true || border === "true") ? true : false;
    const hasScroll = (scrolling === true || scrolling === "true") ? true : false;

    let headers = columns.map(c => {return { type: "data", label: c.toUpperCase(), key: c }});
    if (actions) {
        headers = headers.concat(actions.map(a => {
            if (a.type === "tip") {
                return {
                    type: "tip",
                    content: a.content ? a.content : "",
                    position: a.position ? a.position : "right"
                };
            } else {
                return { 
                    type: "action", 
                    key: a.key,
                    label: a.label ? a.label : "",
                    leftIcon: a.leftIcon ? a.leftIcon : "",
                    rightIcon: a.rightIcon ? a.rightIcon : "",
                    variant: a.variant ? a.variant : "default"
                };
            }
        }));
    }

    const id = useId();

    const getRowClass = (index) => {
        if (variant === "striped") {
            if (index % 2 === (headersHidden ? 1 : 0)) {
                return "bg-white";
            } else {
                return "bg-grey04";
            }
        } else {
            return "bg-white border-b border-grey07";
        }
    };

    const getCellClass = (header, index) => {
        let cellClass = "text-sm";
        if (header.type === "action" || header.type === "tip") {
            cellClass = `${cellClass} font-vg-regular w-[1%] whitespace-nowrap`;
        } else {
            if (index === 0) {
                cellClass = `${cellClass} font-vg-regular text-grey05 py-3 px-6`;
            } else {
                cellClass = `${cellClass} font-vg-book text-grey08 py-3 px-6`;
            }
        }
        return cellClass;
    };

    const getCellContent = (header, index, row) => {
        if (header.type === "action") {
            let IconLeft = null;
            let IconRight = null;
            if (header.leftIcon) {
                IconLeft = lookupIcon(header.leftIcon, "outline");
            }
            if (header.rightIcon) {
                IconRight = lookupIcon(header.rightIcon, "outline");
            }
            let buttonClass = "flex justify-center items-center gap-1.5 font-vg-regular";
            switch (header.variant) {
                case "default":
                    buttonClass = `${buttonClass} text-blue py-3 px-6`;
                    break;
                case "red":
                    buttonClass = `${buttonClass} text-red py-3 px-6`;
                    break;
                case "outline":
                    buttonClass = `${buttonClass} text-blue py-2 px-3 border border-blue rounded-lg`;
                    break;
                case "outline-red":
                    buttonClass = `${buttonClass} text-red py-2 px-3 border border-red rounded-lg`;
                    break;
                default:
                    buttonClass = `${buttonClass} text-blue py-3 px-6`;
                    break;
            }
            return (
                <button
                    className={buttonClass}
                    onClick={() => {
                        handleActionClick(header.key, index);
                    }}
                >
                    {IconLeft && (<IconLeft className="h-5"/>)}
                    {header.label}
                    {IconRight && (<IconRight className="h-5"/>)}
                </button>
            );
        } else if (header.type === "tip") {
            return (
                <IconTooltip content={header.content} position={header.position}/>
            );
        } else {
            const content = row.hasOwnProperty(header.key) ? row[header.key] : "";
            return (
                <span>{content}</span>
            );
        }
    }

    const handleScroll = (e) => {
        if (hasScroll) {
            const el = e.currentTarget;
            const sT = el.scrollTop;
            el.querySelectorAll("thead th").forEach(th =>
                th.style.transform = `translateY(${sT}px)`
            );
        }
    };

    const handleActionClick = (key, index) => {
        if (onActionClick) {
            onActionClick(key, index, data[index]);
        }
    }

    let outerClass = "relative w-full min-h-[44px]";
    let tableClass = className ? `w-full border-collapse ${className}` : "w-full border-collapse";
    let noDataClass = "absolute bottom-0 inset-x-0 flex items-center justify-start z-10 min-h-[44px] px-6";

    if (headersHidden) {
        noDataClass = `${noDataClass} bg-grey04 top-0`;
    } else {
        noDataClass = `${noDataClass} bg-grey04 top-11`;
    }

    if (hasBorder) {
        outerClass = `${outerClass} shadow-table border border-grey07`;
    }

    if (hasScroll) {
        outerClass = `${outerClass} h-full overflow-y-auto`;
        tableClass = `${tableClass} h-full`;
    }

    return(
        <div className={outerClass} onScroll={handleScroll}>
            <table 
                className={tableClass}
                { ...otherProps } 
            >
                <thead className={headersHidden ? "hidden" : ""}>
                    <tr>
                    {headers.map((h, index) => (
                        <th 
                            key={`${id}-th-${index}`} 
                            className="bg-grey04 p-0"
                        >
                            <div className="flex flex-col items-stretch">
                                <div className="h-0 border-t border-grey04"></div>
                                <p className="font-vg-book text-xs text-grey08 py-3 px-6 h-11 text-left">{h.label}</p>
                                <div className="h-0 border-b border-grey07"></div>
                            </div>
                        </th>
                    ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowindex) => (
                        <tr key={`${id}-tr-${rowindex}`} className={getRowClass(rowindex)}>
                            {headers.map((h, colindex) => (
                                <td key={`${id}-tr-${rowindex}-${colindex}`} className={getCellClass(h, colindex)}>
                                    {getCellContent(h, rowindex, row)}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            {data.length === 0 && (
            <div className={noDataClass}>
                <p className="font-vg-regular text-base text-grey08">{noDataMessage ? noDataMessage : "No data to display"}</p>
            </div>
            )}
        </div>
    )
}