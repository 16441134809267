import axios from 'axios';

let IndicatorService = {};

IndicatorService.updateIndicator = (payload) => {
    return axios.put(process.env.REACT_APP_API_URL + '/indicator',
        payload, 
        { withCredentials: true }
    );
};

export default IndicatorService;