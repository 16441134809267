import { Navigate } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';

import AuthService from '../../services/AuthService';
import UiUtils from '../../utils/UiUtils';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function DesktopOnlyPage() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;
    
    const loggedIn = AuthService.isLoggedIn();

    return(
        <>
        {isDesktop && (
            <Navigate to={loggedIn ? "/kit/home" : "/"} replace={true}/>
        )}
        {isMobile && (
            <div className="box-border w-full h-full py-6 flex flex-col items-stretch gap-8">
                <Header title="Desktop<br/>Only" className="mx-5"/>
                <div className="flex flex-col items-stretch gap-8 py-10 px-8">
                    <p className="font-vg-book text-base text-black">
                        The indicator tracking functions of Seedkit are only designed to work on devices with larger screens,
                        such as laptops, desktop computers and large tablets.
                        <br/><br/>
                        Please visit Seedkit on one of these devices to use its full range of functionality.
                    </p>
                </div>
                <Footer className="mx-5"/>
            </div>
        )}
        </>
    );
}