import { useState } from 'react';
import { Tab } from '@headlessui/react';

import * as UiConstants from '../constants/UiConstants';
import * as IndicatorConstants from '../constants/IndicatorConstants';
import * as DataConstants from '../constants/DataConstants';

import TextInput from './TextInput';
import Button from './Button';
import Table from './Table';

export default function IndicatorTypePanel({ indicators, reportingNeed, domain, area, type, selection, onTypePanelAction }) {

    let selectIndicatorsByType = selection.filter(indSel => indSel.indicatorType === type);
    selectIndicatorsByType.sort((a, b) => {
        if (a.subIndex < b.subIndex) {
            return -1
        } else if (b.subIndex < a.subIndex) {
            return 1;
        } else {
            return 0;
        }
    });

    let allIndicatorsByType = indicators.filter(indAll => indAll.indicatorType === type && selectIndicatorsByType.map(si => si.key).indexOf(indAll.key) === -1);
    allIndicatorsByType.sort((a, b) => {
        if (a.subIndex < b.subIndex) {
            return -1
        } else if (b.subIndex < a.subIndex) {
            return 1;
        } else {
            return 0;
        }
    });

    const [customTitle, setCustomTitle] = useState("");

    const onSelectedTypeAction = (key, index, row) => {
        let details = null;
        switch (key) {
            case "edit":
                details = { 
                    action: IndicatorConstants.ActionType.EDIT_INDICATOR,
                    args: row._id
                };
                break;
            case "delete":
                if (onTypePanelAction) {
                    if (window.confirm(`Are you sure you want to remove this indicator from your list of ${type} indicators?`)) {
                        details = { 
                            action: IndicatorConstants.ActionType.DELETE_INDICATOR,
                            args: row._id
                        };
                        onTypePanelAction(details);
                    }
                }
                break;
            default:
                break;
        }
        if (details) {
            if (onTypePanelAction) {
                onTypePanelAction(details);
            }
        }
    };

    const onAllTypeAction = (key, index, row) => {
        if (key === "add") {
            if (onTypePanelAction) {
                let indicator = JSON.parse(JSON.stringify(row));
                indicator.outcomeArea = area;
                onTypePanelAction({ 
                    action: IndicatorConstants.ActionType.ADD_INDICATOR,
                    args: {
                        indicator: indicator
                    }
                });
            }
        }
    };

    const onCustomTitleChange = (e) => {
        setCustomTitle(e.target.value);
    }

    const onAddCustomClick = () => {
        if (onTypePanelAction) {
            // NB: Using Monthly as default frequency type
            onTypePanelAction({
                action: IndicatorConstants.ActionType.CREATE_INDICATOR_WITH_TYPE,
                args: {
                    title: customTitle,
                    reportingNeed: reportingNeed,
                    domain: domain,
                    outcomeArea: area,
                    type: type,
                    frequency: DataConstants.FrequencyType.MONTHLY
                }
            });
        }
    }

    return (
        <Tab.Panel className="flex flex-col items-stretch gap-[54px]">
            <div className="flex items-center justify-between gap-6">
                <TextInput 
                    variant="default" 
                    label={`Create custom ${type}`} 
                    help={`Used for typing out and creating a custom ${type} indicator`}
                    className="grow"
                    value={customTitle}
                    onChange={onCustomTitleChange}
                />
                <Button 
                    variant="outline" 
                    size="large" 
                    label="Add" 
                    leftIcon="PlusIcon" 
                    onClick={onAddCustomClick}
                />
            </div>
            <div className="flex flex-col items-stretch gap-10">
                <div>
                    <h6 className="font-vg-regular text-xl leading-120 text-black pb-1 w-full border-b border-grey">
                        {`Default ${type} indicators for '${area}'`}
                    </h6>
                    <Table
                        columns={["title"]}
                        data={selectIndicatorsByType}
                        variant="lined"
                        hideHeaders="true"
                        scrolling="false"
                        actions={UiConstants.SELECTED_INDICATOR_ACTIONS}
                        onActionClick={onSelectedTypeAction}
                        noDataMessage="There are no Indicators to display"
                    />
                </div>
                <div>
                    <h6 className="font-vg-regular text-xl leading-120 text-black pb-1 w-full border-b border-grey">
                        {`Other ${type} indicators in this Domain`}
                    </h6>
                    <Table
                        columns={["title"]}
                        data={allIndicatorsByType}
                        variant="lined"
                        hideHeaders="true"
                        scrolling="false"
                        actions={UiConstants.ALL_INDICATOR_ACTIONS}
                        onActionClick={onAllTypeAction}
                        noDataMessage="There are no Indicators to display"
                    />
                </div>
            </div>
        </Tab.Panel>
    );
}