import axios from 'axios';

let LibraryService = {};

LibraryService.getFullLibrary = () => {
    return axios.get(process.env.REACT_APP_API_URL + '/library', 
        { withCredentials: true }
    );
};

export default LibraryService;