import { Tab } from '@headlessui/react';

import * as IndicatorConstants from '../constants/IndicatorConstants';
import * as UiConstants from '../constants/UiConstants';

import IndicatorPanelHeader from './IndicatorPanelHeader';
import Tag from './Tag';
import IndicatorOutcomeAreaButton from './IndicatorOutcomeAreaButton';

export default function IndicatorOutcomeAreaPanel({domain, outcomeAreas, selectedValue, onStepPanelAction}) {

    return(
        <Tab.Panel className="flex flex-col items-stretch px-6 py-8 gap-10 rounded-lg bg-grey04 border border-grey03">
            <div className="flex flex-col items-stretch gap-3">
                <IndicatorPanelHeader 
                    title="Select Outcome area"
                    subtitle="You can customise the name of this outcome area once finished."
                    tipContent=""
                />
                <div className="flex items-center gap-6">
                    <p className="font-vg-regular text-xs text-black">Domain:</p>
                    <Tag text={domain} size="large" color={UiConstants.TagColor.BLACK_OUTLINE}/>
                </div>
            </div>
            <div className="grid grid-cols-3 gap-6">
                {outcomeAreas.map((area, i) => (
                    <IndicatorOutcomeAreaButton
                        key={`oa-${i}`}
                        label={area}
                        value={area}
                        state={(selectedValue === "" ? "default" : (selectedValue === area ? "selected" : "unselected"))}
                        onChange={(value, state) => {
                            if (onStepPanelAction) {
                                if (state === "selected") {
                                    onStepPanelAction({
                                        action: IndicatorConstants.ActionType.DESELECT_AREA,
                                        args: value
                                    });
                                } else {
                                    onStepPanelAction({
                                        action: IndicatorConstants.ActionType.SELECT_AREA,
                                        args: value
                                    });
                                }
                            }
                        }}
                    />
                ))}
            </div>
        </Tab.Panel>
    );
}