import { useState, useMemo, useContext, useEffect, useCallback } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getExpandedRowModel,
    flexRender,
    createColumnHelper
} from '@tanstack/react-table';
import { 
    ChevronUpIcon, 
    ChevronDownIcon, 
    PencilSquareIcon, 
    DocumentDuplicateIcon, 
    TrashIcon 
} from '@heroicons/react/24/solid';

import * as EntityConstants from '../constants/EntityConstants';
import * as IndicatorConstants from '../constants/IndicatorConstants';
import { GlobalContext } from '../context/GlobalContext';

export default function PathwayTable({ selectedUnit, domain, onTableAction, refresh, userCanEdit }) {
    const PATHWAY_EDIT = true;
    const PATHWAY_DUPLICATE = false;
    const PATHWAY_DELETE = true;

    const INDICATOR_EDIT = true;
    const INDICATOR_DUPLICATE = false;
    const INDICATOR_DELETE = true;

    const { context } = useContext(GlobalContext);

    const [expanded, setExpanded] = useState({});

    const handleActionClick = useCallback((type, pathwayId, indicatorId, title) => {
        if (onTableAction) {
            if (indicatorId !== "") {
                // Indicator
                const args = {
                    indicatorId: indicatorId,
                    pathwayId: pathwayId,
                    title: title
                };
                switch (type) {
                    case IndicatorConstants.ActionType.EDIT:
                        onTableAction({ action: IndicatorConstants.ActionType.EDIT_SAVED_INDICATOR, args: args });
                        break;
                    case IndicatorConstants.ActionType.DUPLICATE:
                        onTableAction({ action: IndicatorConstants.ActionType.DUPLICATE_SAVED_INDICATOR, args: args });
                        break;
                    case IndicatorConstants.ActionType.DELETE:
                        onTableAction({ action: IndicatorConstants.ActionType.DELETE_SAVED_INDICATOR, args: args });
                        break;
                    default:
                        break;
                }
            } else {
                // Pathway
                const args = {
                    pathwayId: pathwayId,
                    title: title
                }
                switch (type) {
                    case IndicatorConstants.ActionType.EDIT:
                        onTableAction({ action: IndicatorConstants.ActionType.EDIT_PATHWAY, args: args });
                        break;
                    case IndicatorConstants.ActionType.DUPLICATE:
                        onTableAction({ action: IndicatorConstants.ActionType.DUPLICATE_PATHWAY, args: args });
                        break;
                    case IndicatorConstants.ActionType.DELETE:
                        onTableAction({ action: IndicatorConstants.ActionType.DELETE_PATHWAY, args: args });
                        break;
                    default:
                        break;
                }
            }
        }
    }, [onTableAction]);
    
    /* Columns */

    const headerTextClass = "font-vg-book text-base text-black py-4 px-6";
    const cellTextClass = "font-vg-book text-sm text-black py-4 px-6";

    const columnHelper = createColumnHelper();

    const columnsEdit = useMemo(() => [
        columnHelper.accessor("pathwayId", {
            header: () => "",
            cell: info => info.getValue()
        }),
        columnHelper.accessor("indicatorId", {
            header: () => "",
            cell: info => info.getValue()
        }),
        columnHelper.accessor("title", {
            header: () => (
                <p className={headerTextClass}>Impact Pathway / Indicators</p>
            ),
            cell: ({row, getValue}) => (
                <p className={row.depth > 0 ? `${cellTextClass} ml-5` : cellTextClass}>{getValue()}</p>
            )
        }),
        columnHelper.display({
            id: "expander",
            header: () => "",
            cell: ({row, getValue}) => (
                <>
                {row.getCanExpand() && (
                    <button 
                        className="flex items-center justify-start gap-2 py-4 px-6"
                        {...{onClick: row.getToggleExpandedHandler()}}
                    >
                        {row.getIsExpanded() ? (
                            <ChevronUpIcon className="w-6 h-6 text-blue"/>
                        ) : (
                            <ChevronDownIcon className="w-6 h-6 text-blue"/>
                        )}
                    </button>
                )}
                </>
            )
        }),
        columnHelper.accessor("indicatorType", {
            header: () => (<p className={headerTextClass}>Type</p>),
            cell: info => (<p className={cellTextClass}>{info.getValue()}</p>)
        }),
        columnHelper.accessor("frequency", {
            header: () => (<p className={headerTextClass}>Data Entry Interval</p>),
            cell: info => (<p className={cellTextClass}>{info.getValue()}</p>)
        }),
        columnHelper.accessor("edit", {
            header: () => "",
            cell: ({row, getValue}) => (<>
                {getValue() === true && (
                    <button 
                        className="flex items-center justify-start gap-4 py-4 px-6" 
                        {...{onClick: () => handleActionClick(
                            IndicatorConstants.ActionType.EDIT, 
                            row.getValue("pathwayId"), 
                            row.getValue("indicatorId"),
                            row.getValue("title")
                        )}}
                    >
                        <PencilSquareIcon className="w-6 h-6 text-blue"/>
                    </button>
                )}
            </>)
        }),
        columnHelper.accessor("duplicate", {
            header: () => "",
            cell: ({row, getValue}) => (<>
                {getValue() === true && (
                    <button 
                        className="flex items-center justify-start gap-4 py-4 px-6" 
                        {...{onClick: () => handleActionClick(
                            IndicatorConstants.ActionType.DUPLICATE, 
                            row.getValue("pathwayId"), 
                            row.getValue("indicatorId"),
                            row.getValue("title")
                        )}}
                    >
                        <DocumentDuplicateIcon className="w-6 h-6 text-blue"/>
                    </button>
                )}
            </>)
        }),
        columnHelper.accessor("delete", {
            header: () => "",
            cell: ({row, getValue}) => (<>
                {getValue() === true && (
                    <button 
                        className="flex items-center justify-start gap-4 py-4 px-6" 
                        {...{onClick: () => handleActionClick(
                            IndicatorConstants.ActionType.DELETE, 
                            row.getValue("pathwayId"), 
                            row.getValue("indicatorId"),
                            row.getValue("title")
                        )}}
                    >
                        <TrashIcon className="w-6 h-6 text-red"/>
                    </button>
                )}
            </>)
        }),
    ], [columnHelper, handleActionClick]);

    const columnsNonEdit = useMemo(() => [
        columnHelper.accessor("pathwayId", {
            header: () => "",
            cell: info => info.getValue()
        }),
        columnHelper.accessor("indicatorId", {
            header: () => "",
            cell: info => info.getValue()
        }),
        columnHelper.accessor("title", {
            header: () => (
                <p className={headerTextClass}>Impact Pathway / Indicators</p>
            ),
            cell: ({row, getValue}) => (
                <p className={row.depth > 0 ? `${cellTextClass} ml-5` : cellTextClass}>{getValue()}</p>
            )
        }),
        columnHelper.display({
            id: "expander",
            header: () => "",
            cell: ({row, getValue}) => (
                <>
                {row.getCanExpand() && (
                    <button 
                        className="flex items-center justify-start gap-2 py-4 px-6"
                        {...{onClick: row.getToggleExpandedHandler()}}
                    >
                        {row.getIsExpanded() ? (
                            <ChevronUpIcon className="w-6 h-6 text-blue"/>
                        ) : (
                            <ChevronDownIcon className="w-6 h-6 text-blue"/>
                        )}
                    </button>
                )}
                </>
            )
        }),
        columnHelper.accessor("indicatorType", {
            header: () => (<p className={headerTextClass}>Type</p>),
            cell: info => (<p className={cellTextClass}>{info.getValue()}</p>)
        }),
        columnHelper.accessor("frequency", {
            header: () => (<p className={headerTextClass}>Data Entry Interval</p>),
            cell: info => (<p className={cellTextClass}>{info.getValue()}</p>)
        }),
    ], [columnHelper]);

    const initialState = { 
        columnVisibility: {
            pathwayId: false, 
            indicatorId: false
        },
        expanded: false 
    };

    /* Data Functions */

    const getTypeSubIndexOrdinal = (type, subIndex) => {
        switch (type) {
            case IndicatorConstants.IndicatorType.INPUT:
                return 100 + subIndex;              
            case IndicatorConstants.IndicatorType.OUTPUT:
                return 200 + subIndex;              
            case IndicatorConstants.IndicatorType.OUTCOME:
                return 300 + subIndex;               
            default:
                return subIndex;
        }
    }

    const compileData = useCallback(() => {
        let pathways = [];
        let indicators = [];
        let index = -1;
        switch (selectedUnit.type) {
            case EntityConstants.EntityType.ENTERPRISE:
                if (context.organisation && context.organisation.enterprises) {
                    index = context.organisation.enterprises.map(e => e._id).indexOf(selectedUnit.id);
                    if (index > -1) {
                        const enterprise = context.organisation.enterprises[index];
                        indicators = enterprise.indicators ? enterprise.indicators : [];
                        pathways = enterprise.pathways ? enterprise.pathways : [];
                    }
                }
                break;
            case EntityConstants.EntityType.PROGRAM:
                if (context.organisation && context.organisation.programs) {
                    index = context.organisation.programs.map(pg => pg._id).indexOf(selectedUnit.id);
                    if (index > -1) {
                        const program = context.organisation.programs[index];
                        indicators = program.indicators ? program.indicators : [];
                        pathways = program.pathways ? program.pathways : [];
                    }
                }
                break;
            case EntityConstants.EntityType.PROJECT:
                if (context.organisation && context.organisation.projects) {
                    index = context.organisation.projects.map(pj => pj._id).indexOf(selectedUnit.id);
                    if (index > -1) {
                        const project = context.organisation.projects[index];
                        indicators = project.indicators ? project.indicators : [];
                        pathways = project.pathways ? project.pathways : [];
                    }
                }
                break;
            default:
                break;
        }
    
        indicators = indicators.filter(ind => ind.domain === domain);
        pathways = pathways.filter(pwy => pwy.domain === domain);
    
        let tableData = [];
        pathways.forEach(pathway => {
            let item = {
                pathwayId: pathway._id,
                indicatorId: "",
                title: pathway.title,
                indicatorType: "",
                frequency: "",
                edit: PATHWAY_EDIT,
                duplicate: PATHWAY_DUPLICATE,
                delete: PATHWAY_DELETE,
                subRows: []
            };
            let subRows = [];
            pathway.indicators.forEach(key => {
                const index = indicators.map(ind => ind.key).indexOf(key);
                if (index > -1) {
                    const indicator = indicators[index];
                    const subitem = {
                        pathwayId: pathway._id,
                        indicatorId: indicator._id,
                        title: indicator.title,
                        indicatorType: indicator.indicatorType,
                        subIndex: indicator.subIndex,
                        frequency: indicator.frequency ? indicator.frequency : "[Not Set]",
                        edit: INDICATOR_EDIT,
                        duplicate: INDICATOR_DUPLICATE,
                        delete: INDICATOR_DELETE
                    };
                    subRows.push(subitem);
                }
            });
            subRows.sort((a,b) => {
                const ordA = getTypeSubIndexOrdinal(a.indicatorType, a.subIndex);
                const ordB = getTypeSubIndexOrdinal(b.indicatorType, b.subIndex);
                if (ordA < ordB) {
                    return -1
                } else if (ordB < ordA) {
                    return 1;
                } else {
                    return 0;
                }
            });
            item.subRows = subRows;
            tableData.push(item);
        });
        return tableData;
    }, [INDICATOR_DELETE, INDICATOR_DUPLICATE, INDICATOR_EDIT, PATHWAY_DELETE, PATHWAY_DUPLICATE, PATHWAY_EDIT, 
        context.organisation, domain, selectedUnit.id, selectedUnit.type]);

    const [data, setData] = useState(compileData());

    let columns = userCanEdit ? columnsEdit : columnsNonEdit;

    const table = useReactTable({
        data,
        columns,
        initialState,
        state: {
          expanded,
        },
        autoResetExpanded: false,
        onExpandedChange: setExpanded,
        getSubRows: row => row.subRows,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel()
    })

    /* Interaction Functions */
    
    /*
    function handleScroll(e) {
        if (hasScroll) {
            const el = e.currentTarget;
            const sT = el.scrollTop;
            el.querySelectorAll("thead th").forEach(th =>
                th.style.transform = `translateY(${sT}px)`
            );
        }
    };
    */

    /* Formatting Functions */

    const rowClassNames = (row) => {
        if (row.depth > 0) {
            return "bg-white";
        } else {
            return (row.index % 2 === 0 ? "bg-grey04" : "bg-white");
        }
    }

    const cellClassNames = (id) => {
        if (id === "title") {
            return "text-left";
        } else if (id === "indicatorType") {
            return "text-left w-32";
        } else if (id === "frequency") {
            return "text-left w-52";
        } else {
            return "text-left w-[1%] whitespace-nowrap";
        }
    }

    /* Use Effect Functions */

    useEffect(() => {
        if (refresh) {
            setData(compileData());
            onTableAction({ action: IndicatorConstants.ActionType.REFRESH_DONE_PATHWAY_TABLE });
        }
    }, [refresh, compileData, onTableAction]);
   
    return (
        <table className="w-full border-collapse rounded-lg border border-grey-03">
            <thead className=" border-b border-grey-03 bg-white">
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <th key={header.id} className={cellClassNames(header.column.id)}>
                                {flexRender(header.column.columnDef.header, header.getContext())}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map(row => (
                    <tr key={row.id} className={rowClassNames(row)}>
                        {row.getVisibleCells().map(cell => (
                            <td key={cell.id} className={cellClassNames(cell.column.id)}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}