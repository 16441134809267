import { useState, useEffect } from 'react';
import { Buffer } from 'buffer';
import { useWindowSize } from 'usehooks-ts';

import UiUtils from '../../utils/UiUtils';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function PrivacyPage() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    let containerClass = "box-border w-full h-full flex flex-col items-stretch";

    containerClass = isDesktop ? `${containerClass} px-16 py-6 gap-16` : `${containerClass} px-5 py-6 gap-12`;

    const p1a = `In addition to the information that users provide through their registration and use of Seedkit, users may contact the researchers via email.`;
	const p1b = `In addition to the information that users provide through their registration and use of Seedkit, 
    users may contact the researchers via the email address: `;
    const p1c = `. By doing so, the research team member will receive your email address and any personal information you provide in your email correspondence.`;

    const p2a = `Under the terms of current legislation, you have the right to access, or alter, any information 
    concerning yourself that is recorded on Seedkit. `;
    const p2b = `You may request access to this information by emailing `;

    const p3 = `Email: `;

	const [para1, setPara1] = useState(p1a);
    const [para2, setPara2] = useState(p2a);
    const [para3, setPara3] = useState('');

    useEffect(() => {
		const e1 = Buffer.from('PGEgaHJlZj0ibWFpbHRvOmFkbWluQHNlZWRraXQuY29tLmF1Ij5hZG1pbkBzZWVka2l0LmNvbS5hdTwvYT4=', 'base64').toString('ascii');
        const e2 = Buffer.from('PGEgaHJlZj0ibWFpbHRvOmluZm9wcml2YWN5QHN3aW4uZWR1LmF1Ij5pbmZvcHJpdmFjeUBzd2luLmVkdS5hdTwvYT4=', 'base64').toString('ascii');

		setPara1(p1b + e1 + p1c);
        setPara2(p2a + p2b + e1);
        setPara3(p3 + e2);
    }, [p1b, p1c, p2a, p2b, p3]);

    return(
        <div className={containerClass}>
            <Header title="Privacy"/>
            <div className="container mx-auto text-black pb-10 max-w-content-narrow">
                <h2 className="font-vg-medium text-xl my-5">Seedkit value your privacy</h2>
                <p className="font-vg-book text-base mb-5">
                Swinburne University of Technology <strong>(Swinburne)</strong> and the University of Melbourne <strong>(UoM)</strong> through 
                the Social Enterprise Evaluation and Data Kit (Seedkit) study team, acknowledges and respects the privacy of individuals. 
                Given the nature of the online service provided on the Seedkit website, you may have provided, or be asked to provide, 
                'identifying information' (i.e. information that is unique to you or your organisation, and identifies you or others), 
                as well as information that may be considered confidential or sensitive (i.e. information that is not necessarily unique 
                to you or your organisation, but which you could reasonably expect to be managed respectfully).
                </p>
                <p className="font-vg-book text-base">
	                This policy outlines <em>what</em> information is recorded on the Seedkit website; the <em>purpose</em> of collecting this 
	                information; how the information is <em>used</em> and <em>protected</em>, and your <em>rights and responsibilities</em> with 
	                respect to the security of the personal information you provide, as a user of the Seedkit.
                </p>

                <h2 className="font-vg-medium text-xl my-5">What information does Seedkit collect?</h2>
                <p className="font-vg-book text-base mb-5">
                    Information is provided to Seedkit from registered users of the site, through the registration process and the 
                    completion of online forms.
                </p>
                <p className="font-vg-book text-base mb-5">
                    The registration process requires all users to provide a valid email account as their username, and a password for this account. 
                    All subsequent information regarding site use, such as log in times and dates, page views, and responses to online surveys 
                    is matched to this username.
                </p>
                <p className="font-vg-book text-base mb-5">
                    The following information is electronically recorded against the username of Seedkit users: 
                    your responses to Seedkit data input.
                </p>
                <p className="font-vg-book text-base">
                    Users are also asked to provide a valid email address. The purpose of collecting this information is so 
                    that you can be contacted if there are any queries or issues with your account; and to enable secure access 
                    to your user account, comprising of:
                </p>
                <ol className="list-decimal font-vg-book text-base mt-2">
		            <li className="mb-2 ml-8">
                        Correctly entering your valid email address, and
                    </li>
		            <li className="mb-2 ml-8">
                        Correctly entering your password
                    </li>  
	            </ol>
                <p className="font-vg-book text-base" dangerouslySetInnerHTML={{__html: para1}}/>

                <h2 className="font-vg-medium text-xl my-5">Why do we collect your information?</h2>
                <p className="font-vg-book text-base mb-5">
                    As a provider of an online service, Seedkit administrators request users to provide identifying, sensitive 
                    or confidential information for the following purposes:
                </p>	  
                <ol className="list-decimal font-vg-book text-base mt-2">
		            <li className="mb-2 ml-8">
                        In order to ensure that Seedkit can securely manage and process data entered by different users, 
                        the data you enter needs to be linked to identifying/verifiable details such as your user account.
                    </li>
		            <li className="mb-2 ml-8">
                        To enable Seedkit to be as relevant and effective as possible for users, the research team needs 
                        to be able to track how user behaviour changes over time, which requires that data is attributable 
                        to an individual user.
                    </li>
		            <li className="mb-2 ml-8">
                        For the purposes of collating and delivering reports to users, the team also needs to be able to contact you 
                        by email, to send you a copy of the report generated by Seedkit based on the data you entered.
                    </li>   
	            </ol>
                <p className="font-vg-book text-base mb-5">
                    Although the user is asked to provide a username and email address, this information is not shared or used for any purposes 
	                outside of those described in the Seedkit <a href="/terms">Terms of use.</a>
                </p>
                <p className="font-vg-book text-base">	
	                The data collected by Seedkit will be de-identified, collated, and reported in group format in any publications or 
	                presentations arising from this research.
                </p>

                <h2 className="font-vg-medium text-xl my-5">How do we protect your information?</h2>
                <p className="font-vg-book text-base mb-5">
                    The Seedkit team adheres to the principles outlined in the Privacy and Data Protection Act 2014 (Vic) 
                    regarding the protection and security of personal information. In accordance with these principles, 
                    personal information that is recorded on the Seedkit site is used only for the purpose stipulated 
                    at the time that the information is collected, and cannot be revealed, disclosed, sold, distributed, 
                    rented, licensed, shared or otherwise passed on to any third party, unless: (1) we have your express 
                    permission to do so; (2) where required by law to provide information in response to a subpoena or warrant, 
                    or (3) in cases where the potential risk of harm, or threat to the safety of a consumer or another, 
                    requires such disclosure for the purpose of minimising or negating that risk.
                </p>
                <p className="font-vg-book text-base">
                    In order to protect the privacy of consumers, Seedkit uses and implements professional and industry standards 
                    for the security and protection of personal information. Current strategies that promote the highest standards 
                    of data security include:
                </p>
                <ul className="list-disc font-vg-book text-base mt-2">
                    <li className="mb-2 ml-8">
                        Our hosting platform employs strict access controls and best practice network layer security.
                    </li>
                    <li className="mb-2 ml-8">
                        Our application has been designed to validate all access and data inputs to protect both the application and database 
                        against malicious attacks.
                    </li>
                    <li className="mb-2 ml-8">
                        When using Seedkit, users connect to our site via HTTPS connections, which are more secure versions of 
                        the standard http protocol. Our site and the server it resides on are identified and authenticated 
                        by SSL certificates in order to use HTTPS, which means users can be assured they are always communicating 
                        with the real Seedkit website.
                    </li>
                    <li className="mb-2 ml-8">
                        All personally identifiable data stored in our database is protected by encryption.
                    </li>
                    <li className="mb-2 ml-8">
                        Access to data through our application is governed by tightly defined role-based access protocols.
                    </li>   
                </ul>

                <h2 className="font-vg-medium text-xl my-5">Cookies</h2>
                <p className="font-vg-book text-base mb-5">
                    Seedkit temporarily installs "cookies" on your computer while you are logged into the site. 
                    These cookies communicate to secure servers, to authenticate access to the website for 
                    the duration of your session, and are set to expire when the browsing session ends.
                </p>
                <p className="font-vg-book text-base">
                    Cookies are used to help you navigate Seedkit's website pages, not to record personal information 
                    about you or track how you are using your computer/device.  
                </p>

                <h2 className="font-vg-medium text-xl my-5">Your rights and responsibilities</h2>
                <p className="font-vg-book text-base mb-5" dangerouslySetInnerHTML={{__html: para2}}/>
                <p className="font-vg-book text-base mb-5">
		            If you feel that your privacy has been breached, the contact details for 
                    Swinburne University's Privacy Officer are provided below.
                </p>
                <p className="font-vg-book text-base">
                    In order to further protect your privacy, the Seedkit team suggest consumers 
                    follow these guidelines when using the site, or communicating personal information with us:
                </p>	  
                <ul className="list-disc font-vg-book text-base mt-2">
                    <li className="mb-2 ml-8">
                        Choose a strong password that does not readily identify you
                    </li>
                    <li className="mb-2 ml-8">
                        Do not disclose your login details to anyone
                    </li>
                    <li className="mb-2 ml-8">
                        Use your browser's private browsing capability if you are concerned about other people looking at your internet history
                    </li>
                    <li className="mb-2 ml-8">
                        Remember to log out after each session
                    </li>  
                </ul>

                <h2 className="font-vg-medium text-xl my-5">Further information</h2>
                <p className="font-vg-book text-base mb-5">
                    If you have any questions, issues, or concerns, or wish to lodge a complaint regarding the collection, use, storage, or 
		            disclosure of your personal information on Seedkit, please contact Swinburne University's Privacy Officer.
                </p>
                <p className="font-vg-book text-base mb-5">
                    Contact details are as follows:
                </p>
                <p className="font-vg-book text-base">Name: Mr Matthew Smith</p>	  
                <p className="font-vg-book text-base">Phone: +61 3 9214 4592</p>	  
                <p className="font-vg-book text-base" dangerouslySetInnerHTML={{__html: para3}}/>
                <p className="font-vg-book text-base">Last updated: 12 September 2023</p>	  
            </div>
            <Footer/>
        </div>
    );
}
