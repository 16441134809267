import { forwardRef } from 'react';

const RadioButton = forwardRef((props, ref) => {
    const { label, name, value, className, outerClass, checked, disabled, onChange, reversed, bold, ...otherProps } = props;
    let labelClass = "flex items-center text-base text-black";
    labelClass = bold ? `font-vg-regular ${labelClass}` : `font-vg-book ${labelClass}`;
    if (className) {
        labelClass = `${labelClass} ${className}`;
    }

    return (
        <div className={outerClass ? outerClass : "w-full"}>
            <label className={`${labelClass} ${reversed ? "flex-row-reverse justify-between" : "gap-2"}`}>
                <input
                    ref={ref} 
                    name={name}
                    value={value} 
                    type="radio" 
                    checked={checked}
                    disabled={disabled}
                    className="h-4 w-4 text-blue border-black !bg-none checked:border-black shadow-[0_0_0_2px_white_inset] focus:ring-white focus:ring-0"
                    onChange={(e) => {
                        if (onChange) {
                            onChange(e);
                        }
                    }}
                    { ...otherProps } 
                />
                <span dangerouslySetInnerHTML={{__html: label}}></span>
            </label>
        </div>
    );
});

export default RadioButton;