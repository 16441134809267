import axios from 'axios';

let CohortService = {};

CohortService.getCohorts = () => {
    return axios.get(process.env.REACT_APP_API_URL + '/cohort', 
        { withCredentials: true }
    );
};

export default CohortService;