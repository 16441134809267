exports.ActionType = {
    EDIT: "edit",
    DUPLICATE: "duplicate",
    DELETE: "delete",
    SELECT_UNIT: "selectUnit",
    CREATE_PATHWAY: "createPathway",
    CREATE_PATHWAY_WITH_DOMAIN: "createPathwayDomain",
    CREATE_DOMAIN: "createDomain",
    SELECT_DOMAIN: "selectDomain",
    DESELECT_DOMAIN: "deselectDomain",
    CHANGE_STEP: "changeStep",
    NEXT_STEP: "nextStep",
    PREVIOUS_STEP: "previousStep",
    COMPLETE_STEP: "completeStep",
    SELECT_AREA: "selectArea",
    DESELECT_AREA: "deselectArea",
    CREATE_AREA: "createArea",
    CHANGE_TYPE: "changeType",
    COMPLETE_TYPE: "completeType",
    ADD_INDICATOR: "addIndicator",
    CREATE_INDICATOR: "createIndicator",
    CREATE_INDICATOR_WITH_TYPE: "createIndicatorWithType",
    EDIT_INDICATOR: "editIndicator",
    DELETE_INDICATOR: "deleteIndicator",
    DUPLICATE_INDICATOR: "duplicateIndicator",
    SAVE_PATHWAY: "savePathway",
    EDIT_PATHWAY: "editPathway",
    DELETE_PATHWAY: "deletePathway",
    DUPLICATE_PATHWAY: "duplicatePathway",
    CHANGE_NAME_PATHWAY: "nameChangePathway",
    EDIT_SAVED_INDICATOR: "editSavedIndicator",
    DUPLICATE_SAVED_INDICATOR: "duplicateSavedIndicator",
    DELETE_SAVED_INDICATOR: "deleteSavedIndicator",
    REFRESH_DONE_PATHWAY_TABLE: "refreshDonePathwayTable"
};

exports.ReportingNeed = {
    OPERATIONS: "Operations",
    CHANGING_THE_WORLD: "Changing The World"
};

exports.Domain = {
    ARTS: "Arts and Culture",
    COLLABORATION: "Collaboration",
    COMMUNITY: "Community, Connections and Place-based Development",
    WORK: "Decent Work and Employment",
    ENVIRONMENT: "Environmental Sustainability and Climate Resilience",
    FIRST_NATIONS: "First Nations' Knowledge and Culture",
    HEALTH: "Health and Wellbeing",
    HOUSING: "Housing and Accommodation",
    IMPROVING_SYSTEMS: "Improving Systems, Organisations, and Practices",
    IMPROVING_SYSTEMS_ALT: "Improving Systems, Organisations and Practices",
    GOVERNANCE: "Organisational Governance",
    EQUITY: "Representation and Equity in Governance",
    EDUCATION: "Training and Education",
    FINANCIAL_MANAGEMENT: "Financial Management",
    PEOPLE_MANAGEMENT: "People Management",
    SALES_MARKETING: "Sales & Marketing",
    SALES_MARKETING_ALT: "Sales and Marketing"
};

exports.CUSTOM_DOMAIN_VALUE = "CUSTOM";
exports.CUSTOM_DOMAIN_LABEL = "Custom Domain"

exports.CUSTOM_OUTCOME_AREA_VALUE = "CUSTOM";
exports.CUSTOM_OUTCOME_AREA_LABEL = "Custom Outcome Area"

exports.LOADING_MESSAGE = "Loading...";

exports.INDICATOR_STEPS = [
    { 
        title: "Choose domain", 
        completed: false,
        substeps: [], 
    },
    { 
        title: "Select outcome area", 
        completed: false,
        substeps: [], 
    },
    { 
        title: "Add indicators", 
        completed: false,
        substeps: [
            {
                type: "Input",
                title: "Select Inputs",
                subtitle: "Select your input/s and then click \"Next\" to select outputs",
                tip: "Inputs are specific resources that the program/enterprise/organisation uses to address the overall aim",
                tipAfter: "title",
                iconName: "DocumentArrowDown",
                buttonText: "Inputs",
                completed: false
            },
            {
                type: "Output",
                title: "Select Outputs",
                subtitle: "Select your output/s and then click \"Next\" to select outcomes",
                tip: "Outputs are tangible things that are done (activities and products) by the program/enterprise/organisation to achieve the desired outcomes.",
                tipAfter: "title",
                iconName: "DocumentArrowUp",
                buttonText: "Outputs",
                completed: false
            },
            {
                type: "Outcome",
                title: "Select Outcomes",
                subtitle: "Select your outcome/s and then click \"Next\" to review",
                tip: "Outcomes are short to medium term effects of the program/enterprise/organisation and can be positive or negative. ",
                tipAfter: "title",
                iconName: "DocumentChartBar",
                buttonText: "Outcomes",
                completed: false
            },
        ],  
    },
    { 
        title: "View/edit indicators", 
        completed: false,
        substeps: [], 
    },
];

exports.IndicatorType = {
    INPUT: "Input", 
    ACTIVITY: "Activity", 
    OUTPUT: "Output", 
    OUTCOME: "Outcome", 
    IMPACT: "Impact",
    NONE: ""
};

exports.Format = {
    NUMBER: "#",
    PERCENT: "%",
    CURRENCY: "$",
    TEXT: "*",
    NONE: ""
}