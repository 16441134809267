import axios from 'axios';

let ContactService = {};

ContactService.contact = (details) => {
    return axios.post(process.env.REACT_APP_API_URL + '/contact', {
        firstName: details.firstName,
        lastName: details.lastName,
        phone: details.phone,
        email: details.email,
        query: details.query,
        useCaptcha: details.useCaptcha,
        captcha: details.captcha
    });
};

export default ContactService;