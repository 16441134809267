import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';

import AuthService from '../../services/AuthService';
import UiUtils from '../../utils/UiUtils';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function ForgotVerifyPage() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    let containerClass = "box-border w-full h-full flex flex-col items-stretch";

    containerClass = isDesktop ? `${containerClass} px-16 py-6 gap-16` : `${containerClass} px-5 py-6 gap-12`;

    const [verifyMessage, setVerifyMessage] = useState('');
    const [verifyClass, setVerifyClass] = useState('reglog-submit-ok');

    const params = useParams();
    const navigate = useNavigate();

    const authToken = params.token;
    const accessCode = params.code;

    useEffect(() => {
		AuthService.verifyForgotPassword(authToken, accessCode)
        .then((_) => {
            setVerifyClass('text-black');
            setVerifyMessage('Account access verified. Redirecting to password change page...');
            navigate('/password/change');
        })
        .catch(error => {
            console.log('error', error);
            setVerifyClass('text-red');
            setVerifyMessage(error.response && error.response.data ? error.response.data.message || error.message : error.message);
        });
    }, [accessCode, authToken, navigate]);

    return(
    <div className={containerClass}>
        <Header title="Forgot Password"/>
        <div className="container flex flex-col w-full h-96 justify-center items-center font-vg-medium text-black text-xl">
            <h2 className="font-vg-medium text-black text-3xl text-center mt-12">
                Verifying Access
            </h2>
            <p className={`font-vg-bold text-base my-5 min-h-8 leading-8 ${verifyClass}`}>
                {verifyMessage}
            </p>
        </div>
        <Footer/>
    </div>
    );
}