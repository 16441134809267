import { useWindowSize } from 'usehooks-ts';

import UiUtils from '../../utils/UiUtils';
import * as DashboardConstants from '../../constants/DashboardConstants';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import DashboardCanvasLexicalCard from '../../components/DashboardCanvasLexicalCard';

export default function TestPage() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    let containerClass = "box-border w-full h-full flex flex-col items-stretch";

    containerClass = isDesktop ? `${containerClass} px-16 py-6 gap-16` : `${containerClass} px-5 py-6 gap-12`;

    return(
        <div className={containerClass}>
            <Header title="Test"/>
            <div className="flex flex-col w-full h-[600px] justify-center items-center">
                <div className="w-[1200px] h-[600px] flex flex-col items-stretch gap-12">
                    <div className="grid w-full h-full grid-cols-4 grid-rows-3 gap-6 overflow-hidden">
                        <DashboardCanvasLexicalCard
                            row={1} 
                            col={1} 
                            width={4} 
                            height={3} 
                            details={{
                                title: "",
                                text: ""
                            }} 
                            mode={DashboardConstants.CanvasMode.EDIT}
                        />
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}