import axios from 'axios';

let ProgDataService = {};

ProgDataService.getProgProjectData = () => {
    // TODO: getProgProjectData
};

ProgDataService.updateProgProjectData = (payload) => {
    return axios.put(process.env.REACT_APP_API_URL + '/progprojectdata', 
        payload, 
        { withCredentials: true }
    );
};

export default ProgDataService;