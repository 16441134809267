import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';

import UiUtils from '../../utils/UiUtils';
import * as UiConstants from '../../constants/UiConstants';
import ResourceService from '../../services/ResourceService';

import Header from '../../components/Header';
import TagButton from '../../components/TagButton';
import SelectDropdown from '../../components/SelectDropdown';
import ResourceBlock from '../../components/ResourceBlock';
import Pagination from '../../components/Pagination';
import FaqSet from '../../components/FaqSet';
import Footer from '../../components/Footer';
import Button from '../../components/Button';

export default function ResourcesPage() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    const navigate = useNavigate();

    const [state, setState] = useState({
        resources: [],
        pageResources: [],
        totalPages: 0,
        page: 0,
        pageLength: 0,
        paginationStart: 0,
        category: UiConstants.RESOURCE_TYPE.All,
        sortSelected: UiConstants.RESOURCE_SORT_OPTIONS[0],
        loaded: false,
        error: "",
        subtitle: ""
    });

    /* Resource Loading Functions */

    const loadResources = useCallback(() => {
        ResourceService.getResources(true, UiConstants.RESOURCE_TYPE.All)
        .then(response => {
            const data = response.data;
            const rsrcs = data.resources;
            const newState = {
                resources: rsrcs,
                pageResources: rsrcs,
                totalPages: Math.ceil(rsrcs.length / UiConstants.RESOURCE_PAGE_SIZE),
                page: 0,
                paginationStart: 0,
                category: UiConstants.RESOURCE_TYPE.All,
                sortSelected: UiConstants.RESOURCE_SORT_OPTIONS[0],
                loaded: true,
                error: "",
                subtitle: ""
            };
            setState(newState);
        })
        .catch(err => {
            console.log(err);
            setState({
                resources: [],
                pageResources: [],
                totalPages: 0,
                page: 0,
                paginationStart: 0,
                category: UiConstants.RESOURCE_TYPE.All,
                sortSelected: UiConstants.RESOURCE_SORT_OPTIONS[0],
                loaded: true,
                error: "Unable to load resources. Please try again later",
                subtitle: ""
            });
        });
    }, [setState]);

    useEffect(() => {
        loadResources();
    }, [loadResources]);

    /* Interaction Functions */

    const doSort = (type, res) => {
        let sortedRes = JSON.parse(JSON.stringify(res));
        switch (type) {
            case UiConstants.RESOURCE_SORT_TYPE.AlphaAsc:
                sortedRes.sort((a, b) => {
                    let lowerA = a.title.toLowerCase();
                    let lowerB = b.title.toLowerCase();
                    return (lowerA < lowerB) ? -1 : (lowerA > lowerB) ? 1 : 0;
                })
                break;
            case UiConstants.RESOURCE_SORT_TYPE.AlphaDesc:
                sortedRes.sort((a, b) => {
                    let lowerA = a.title.toLowerCase();
                    let lowerB = b.title.toLowerCase();
                    return (lowerA > lowerB) ? -1 : (lowerA < lowerB) ? 1 : 0;
                })
                break;
            case UiConstants.RESOURCE_SORT_TYPE.DateAsc:
                sortedRes.sort((a, b) => {
                    return (a.updatedAt < b.updatedAt) ? -1 : (a.updatedAt > b.updatedAt) ? 1 : 0;
                })
                break;
            case UiConstants.RESOURCE_SORT_TYPE.DateDesc:
                sortedRes.sort((a, b) => {
                    return (a.updatedAt > b.updatedAt) ? -1 : (a.updatedAt < b.updatedAt) ? 1 : 0;
                })
                break;
            default:
                break;
        }
        return sortedRes;
    }

    const onTagClick = (value) => {
        let sort = state.sortSelected.value;
        let newResources = state.pageResources;
        if (value === UiConstants.RESOURCE_TYPE.All) {
            newResources = doSort(sort, state.resources);
        } else {
            newResources = doSort(sort, state.resources.filter(r => r.category === value));
        }
        const newState = {
            resources: state.resources,
            pageResources: newResources,
            totalPages: Math.ceil(newResources.length / UiConstants.RESOURCE_PAGE_SIZE),
            page: 0,
            paginationStart: 0,
            category: value,
            sortSelected: state.sortSelected,
            loaded: true,
            error: "",
            subtitle: UiConstants.RESOURCE_TYPE_SUBTITLES[value]
        };
        setState(newState);
    };

    const onSortChange = (option) => {
        const sort = option.value;
        const newResources = sort !== "" ? doSort(sort, state.pageResources) : state.pageResources;
        const newState = {
            resources: state.resources,
            pageResources: newResources,
            totalPages: Math.ceil(newResources.length / UiConstants.RESOURCE_PAGE_SIZE),
            page: 0,
            paginationStart: 0,
            category: state.category,
            sortSelected: option,
            loaded: true,
            error: "",
            subtitle: state.subtitle
        };
        setState(newState);
    };

    const onPageClick = (start, current) => {
        const newState = {
            resources: state.resources,
            pageResources: state.pageResources,
            totalPages: state.totalPages,
            page: current,
            paginationStart: start,
            category: state.category,
            sortSelected: state.sortSelected,
            loaded: true,
            error: "",
            subtitle: state.subtitle
        };
        setState(newState);
    }

    const onContactClick = () => {
        navigate("/contact");
    }

    const getPageStart = () => {
        return state.page * UiConstants.RESOURCE_PAGE_SIZE;
    }

    const getPageEnd = () => {
        let end = (state.page + 1) * UiConstants.RESOURCE_PAGE_SIZE - 1;
        return end >= state.pageResources.length ? state.pageResources.length - 1 : end;
    }

    const getPageText = () => {
        return `Showing <span class="font-vg-regular">${getPageStart() + 1}-${getPageEnd() + 1}</span> of <span class="font-vg-regular">${state.pageResources.length}</span> items`;
    };

    return (
        <>
        {isDesktop && (
            <div className="box-border w-full h-full py-6 flex flex-col items-stretch gap-10">
                <Header title="Resources" className="mx-16"/>
                {state.loaded === false && (
                <div className="flex justify-center items-start max-w-content-narrow mx-auto min-h-[400px]">
                    <h4 className="font-vg-medium text-3xl text-black pt-8">Loading Resources...</h4>
                </div>
                )}
                {state.loaded === true && state.error !== "" && (
                <div className="flex justify-center items-start max-w-content-narrow mx-auto min-h-[400px]">
                    <h4 className="font-vg-medium text-3xl text-black pt-8">{state.error}</h4>
                </div>
                )}
                {state.loaded === true && state.error === "" && (
                <>
                    <div className="flex flex-col items-stretch max-w-content-narrow mx-auto px-16 gap-2 w-full">
                        <div className="flex justify-between items-center">
                            <div className="flex flex-start items-center gap-3">
                                {UiConstants.RESOURCES_FILTER_OPTIONS.filter(rfo => rfo.value !== UiConstants.RESOURCE_TYPE.HowTo).map(rf => (
                                    <TagButton
                                        key={`tag-${rf.value.toLowerCase()}`}
                                        id={`tag-${rf.value.toLowerCase()}`}
                                        color={UiConstants.TagColor.GREEN}
                                        size="large"
                                        label={rf.label}
                                        value={rf.value}
                                        selected={state.category === rf.value}
                                        onClick={onTagClick}
                                    />
                                ))}
                            </div>
                            <div className="w-40">
                                <SelectDropdown
                                    options={UiConstants.RESOURCE_SORT_OPTIONS}
                                    selectedOption={state.sortSelected}
                                    onChange={onSortChange}
                                />
                            </div>
                        </div>
                        <div className="font-vg-book text-base text-black">{state.subtitle}</div>
                    </div>
                    {state.pageResources.length === 0 && (
                    <div className="flex justify-center items-start max-w-content-narrow mx-auto min-h-[400px]">
                        <h4 className="font-vg-medium text-3xl text-black pt-8">There are currently no resources to display.</h4>
                    </div>
                    )}
                    {state.pageResources.length > 0 && (
                    <>
                        <div className="flex flex-col items-stretch gap-1.5 w-full max-w-content-narrow mx-auto px-16">
                            <p className="font-vg-book text-xs text-grey" dangerouslySetInnerHTML={{__html: getPageText()}}/>
                            <div className="grid grid-cols-3 gap-x-6 gap-y-10 min-h-[400px]">
                                {state.pageResources.filter((_,index) => index >= getPageStart() && index <= getPageEnd()).map(res => (
                                    <ResourceBlock
                                        key={`res-block-${res.key}`}
                                        id={`res-block-${res.key}`}
                                        type={res.resourceType}
                                        tags={[{ text: res.category, color: "green" }]}
                                        title={res.title}
                                        text={res.lead}
                                        link={`/resources/${res.key}`}
                                        image={res.heroImage.smallUrl}
                                        imageAlt={res.heroImage.alt}
                                        readTime={res.duration}
                                        durationText={res.durationText}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="flex w-full justify-center">
                            <Pagination
                                pageCount={state.totalPages}
                                current={state.page}
                                windowStart={state.paginationStart}
                                windowSize={UiConstants.RESOURCE_PAGINATION_WINDOW_SIZE}
                                onPageClick={(start, current) => onPageClick(start, current)}
                            />
                        </div>
                    </>
                    )}
                    <div className="bg-grey03 bg-home-faq bg-right-bottom bg-contain bg-no-repeat">
                        <div className="flex flex-col items-stretch justify-center gap-12 px-16 py-20 border-box max-w-content mx-auto">
                            <h3 className="font-vg-medium text-black text-[38px] leading-120 text-center">Frequently asked questions</h3>
                            <div className="flex flex-col items-stretch gap-1">
                                <FaqSet/>
                            </div>
                            <div className="flex flex-col items-center gap-3">
                                <p className="font-vg-regular text-xl text-black">Looking for something different?</p>
                                <Button label="Get in contact" size="large" variant="outline" onClick={onContactClick}/>
                            </div>
                        </div>
                    </div>
                </>
                )}
                <Footer className="mx-16"/>
            </div>
        )}
        {isMobile && (
            <div className="box-border w-full h-full px-5 py-6 flex flex-col items-stretch gap-10">
                <Header title="Resources"/>
                {state.loaded === false && (
                <div className="flex justify-center items-center min-h-[200px]">
                    <h4 className="font-vg-medium text-3xl text-black">Loading Resources...</h4>
                </div>
                )}
                {state.loaded === true && state.error !== "" && (
                <div className="flex justify-center items-center min-h-[200px]">
                    <h4 className="font-vg-medium text-3xl text-black">{state.error}</h4>
                </div>
                )}
                {state.loaded === true && state.error === "" && (
                <>
                    <div className="flex flex-col items-stretch gap-4">
                        <div className="grid grid-cols-3 gap-3">
                        {UiConstants.RESOURCES_FILTER_OPTIONS.filter(rfo => rfo.value !== UiConstants.RESOURCE_TYPE.HowTo).map(rf => (
                            <TagButton
                                key={`tag-${rf.value.toLowerCase()}`}
                                id={`tag-${rf.value.toLowerCase()}`}
                                color={UiConstants.TagColor.GREEN}
                                size="large"
                                label={rf.label}
                                value={rf.value}
                                selected={state.category === rf.value}
                                onClick={onTagClick}
                            />
                        ))}
                        </div>
                        <SelectDropdown
                            options={UiConstants.RESOURCE_SORT_OPTIONS}
                            selectedOption={state.sortSelected}
                            onChange={onSortChange}
                        />
                        <div className="font-vg-book text-base text-black">{state.subtitle}</div>
                    </div>
                    {state.pageResources.length === 0 && (
                    <div className="flex justify-center items-center min-h-[200px]">
                        <h4 className="font-vg-medium text-3xl text-black">There are currently no resources to display.</h4>
                    </div>
                    )}
                    {state.pageResources.length > 0 && (
                    <div className="flex flex-col items-stretch gap-1.5">
                        <p className="font-vg-book text-xs text-grey text-center" dangerouslySetInnerHTML={{__html: getPageText()}}/>
                        <div className="flex flex-col items-stretch gap-6">
                            {state.pageResources.filter((_,index) => index >= getPageStart() && index <= getPageEnd()).map(res => (
                            <ResourceBlock
                                key={`res-block-${res.key}`}
                                id={`res-block-${res.key}`}
                                type={res.resourceType}
                                tags={[{ text: res.category, color: "green" }]}
                                title={res.title}
                                text={res.lead}
                                link={`/resources/${res.key}`}
                                image={res.heroImage.smallUrl}
                                imageAlt={res.heroImage.alt}
                                readTime={res.duration}
                                durationText={res.durationText}
                            />
                            ))}
                            <div className="flex w-full justify-center">
                                <Pagination
                                    pageCount={state.totalPages}
                                    current={state.page}
                                    windowStart={state.paginationStart}
                                    windowSize={UiConstants.RESOURCE_PAGINATION_WINDOW_SIZE}
                                    onPageClick={(start, current) => onPageClick(start, current)}
                                />
                            </div>
                        </div>
                    </div>
                    )}
                    <div className="bg-grey03 bg-home-faq-mobile bg-bottom bg-contain bg-no-repeat px-5 py-16">
                        <div className="flex flex-col items-stretch gap-8">
                            <h3 className="font-vg-medium text-black text-[38px] leading-120 text-center">Frequently asked questions</h3>
                            <div className="flex flex-col items-stretch gap-1">
                                <FaqSet/>
                            </div>
                            <div className="flex flex-col items-stretch gap-3">
                                <p className="font-vg-regular text-xl text-black text-center">Looking for something different?</p>
                                <Button label="Get in contact" size="large" variant="outline" onClick={onContactClick}/>
                            </div>
                        </div>
                    </div>
                </>
                )}
                <Footer/>
            </div>
        )}
        </>
    );
}