import { useState, useContext } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { useWindowSize } from 'usehooks-ts';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import AuthService from '../../services/AuthService';
import { GlobalContext } from '../../context/GlobalContext';
import UiUtils from '../../utils/UiUtils';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';

export default function LoginVerifyManualPage() {
    const { width } = useWindowSize();
    const isMobile = UiUtils.isMobile(width);
    const isDesktop = !isMobile;

    const { context } = useContext(GlobalContext);
    const authToken = context.authToken ? context.authToken : '';

    const errorClass = 'font-vg-regular text-red text-base text-center';
    const doneClass = 'font-vg-regular text-black text-base text-center';
    const [verifyClass, setVerifyClass] = useState(doneClass);
    const [submitHideClass, setSubmitHideClass] = useState('');

    const [verifyMessage, setVerifyMessage] = useState('');

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
            accessCode: Yup.string().required('Please enter the access code')
    });
    const formOptions = { 
        resolver: yupResolver(validationSchema),
        defaultValues: {
            accessCode: ""
        } 
    };

    const {  
        control, 
        handleSubmit
    } = useForm(formOptions);

    const onSubmit = (data) => {
        setSubmitHideClass('hidden');
        setVerifyClass(doneClass);
        setVerifyMessage("Processing...");

        AuthService.verifyLogin(authToken, data.accessCode)
        .then(response => {
            setSubmitHideClass('');
            setVerifyClass(doneClass);
            setVerifyMessage('Login verified. Redirecting to Seedkit tool...');
            navigate('/kit/home');
        })
        .catch(error => {
            setSubmitHideClass('');
            console.log('error', error);
            setVerifyClass(errorClass);
            setVerifyMessage(error.response && error.response.data ? error.response.data.message || error.message : error.message);
        });
    }

    const onRequestCode = () => {
        // TODO : onRequestCode
    }

    return(
        <>
        {authToken ===  "" && (
            <Navigate to="/login" replace={true}/>
        )}
        {authToken !== "" && isDesktop && (
            <div className="box-border w-full h-full px-16 py-6 flex flex-col items-stretch gap-16">
                <Header title="Login"/>
                <div className="grid grid-cols-12 max-w-content-narrow mx-auto">
                    <div className="col-span-5 flex flex-col items-start gap-4 py-12">
                        <h2 className="font-vg-medium text-5xl text-black">Access your Seedkit account</h2>
                        <p className="font-vg-book text-base text-black mb-2">
                            Seedkit helps social enterprises measure and communicate their impact. 
                        </p>
                        <p className="font-vg-book text-base text-black">
                            Our self-managed reporting platform lets you showcase your
                            organisational characteristics, operations, and impacts with customised
                            reports for stakeholders.
                        </p>
                    </div>
                    <div className="col-start-7 col-span-6 flex flex-col items-stretch gap-10 px-8 pt-12 pb-6 rounded-3xl bg-grey03">
                        <h4 className="font-vg-medium text-[32px]">Enter your access code</h4>
                        <form className="flex flex-col items-stretch gap-6 min-h-[245px]" onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name="accessCode"
                                control={control}
                                render={({ field, formState }) => (
                                    <TextInput 
                                        variant={formState.errors.accessCode ? "error" : "default"}
                                        label="Please enter the access code sent to your email"
                                        help={formState.errors.accessCode?.message} 
                                        showHelp="always" 
                                        {...field}
                                    />
                                )}
                            />
                            <Button type="submit" variant="solid" size="large" label="VERIFY" className={submitHideClass}/>
                            <div className="flex flex-col items-stretch gap-3">
                                <div className={`flex gap-1.5 font-vg-book text-xs2 ${submitHideClass}`}>
                                    <p className="text-black">Didn't receive a code?</p>
                                    <button onClick={onRequestCode} className="text-blue underline">click to request another</button>
                                </div>
                                <p className={verifyClass}>{verifyMessage}</p>
                            </div>
                        </form>
                    </div>
                </div>
                <Footer/>
            </div>
        )}
        {authToken !== "" && isMobile && (
            <div className="box-border w-full h-full px-5 py-[34px] flex flex-col items-stretch gap-10">
                <Header title="Login"/>
                <div className="flex flex-col items-stretch gap-4">
                    <h2 className="font-vg-medium text-black text-4xl">Access your Seedkit account</h2>
                    <p className="font-vg-book text-base text-black mb-2">
                        Seedkit helps social enterprises measure and communicate their impact. 
                    </p>
                    <p className="font-vg-book text-base text-black">
                        Our self-managed reporting platform lets you showcase your
                        organisational characteristics, operations, and impacts with customised
                        reports for stakeholders.
                    </p>
                </div>
                <div className="flex flex-col items-stretch gap-8 px-6 pt-12 pb-6 rounded-3xl bg-grey03">
                    <h4 className="font-vg-medium text-black text-[32px]">Enter your access code</h4>
                    <form className="flex flex-col items-stretch gap-6 min-h-[245px]" onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            name="accessCode"
                            control={control}
                            render={({ field, formState }) => (
                                <TextInput 
                                    variant={formState.errors.accessCode ? "error" : "default"}
                                    label="Please enter the access code sent to your email"
                                    help={formState.errors.accessCode?.message} 
                                    showHelp="always" 
                                    {...field}
                                />
                            )}
                        />
                        <Button type="submit" variant="solid" size="large" label="VERIFY" className={submitHideClass}/>
                        <div className="flex flex-col items-stretch gap-3">
                            <div className={`flex gap-1.5 font-vg-book text-xs2 ${submitHideClass}`}>
                                <p className="text-black">Didn't receive a code?</p>
                                <button onClick={onRequestCode} className="text-blue underline">click to request another</button>
                            </div>
                            <p className={verifyClass}>{verifyMessage}</p>
                        </div>
                    </form>
                </div>
                <Footer/>
            </div>
        )}
        </>
    );
}
